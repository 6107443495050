<div class="page-main-container">
	<!-- Service grid -->
	<div class="service">
		<div class="container">
			<div class="section-title-wrapper section-gap">
				<div class="row">
					<div class="col-sm-12 col-md-12 text-center">
						<h2>Fencing</h2>
						<div class="text-left">
							<h4>Gravel Boards</h4>
							<p class="mb-0">
								The ingenious design of our gravel boards ensure that they stack seamlessly on top of one another to form a fence panel at a height to suit you. The boards can be built upon one another in 1 ft increments, allowing you to create your desired fence with ease.
								<br><br>
							</p>


							<h4>UPVC Panels & Concrete Posts</h4>
							<p class="mb-0">
								If you already have concrete fence posts and want to update your old timber panels, our durable UPVC boards slip effortlessly into place to create a full plastic panel without the maintenance requirements of traditional timber fencing.<br><br>
							</p>

							<h4>Opt for Timber Panels, UPVC Posts & Gravel Boards</h4>
							<p class="mb-0">
								Even if you choose timber panels, you can still enjoy the resilient, low-maintenance advantages provided by UPVC posts and gravel boards.<br><br>
							</p>

							<h4>Pricing</h4>
							<p class="mb-0">
								As one of the North-East’s leading providers for decking and fencing both commercially and privately, we pride ourselves on offering competitive, affordable prices which guarantee satisfaction and value for money.
								<br><br>
								Customer service and happiness is the cornerstone of our business and we strive to ensure personalised service to suit your budget. Contact us today for a free quote and begin your journey to transform and maximise the potential of your outdoor space.
								<br><br>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row" angly-serviceGrid [serviceGridContent]="services"></div>
		</div>
	</div>

</div>