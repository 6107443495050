import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './about/about.component';
import { FencingComponent } from './fencing/fencing.component';
import { SupportComponent } from './support/support.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';

export const AppRoutes: Routes = [{
   path: '',
   redirectTo: 'home',
   pathMatch: 'full',
}, {
   path: '',
   component: MainComponent,
   children: [
      {
         path: 'home',
         component: HomeComponent
      }, {
         path: 'contact',
         component: ContactComponent
      }, {
         path: 'features',
         component: FeaturesComponent
      }, {
         path: 'about',
         component: AboutComponent
      }, {
         path: 'fencing',
         component: FencingComponent
      }, {
         path: 'support',
         component: SupportComponent
      }, {
         path: '',
         loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule)
      },
      {
         path: 'sidebar-widgets',
         component: sidebarWidgetsComponent
      }, {
         path: '',
         loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
      }, {
         path: 'about/:keyword', component: AboutComponent
      }
   ]
}];

const config: ExtraOptions = {};

@NgModule({
   imports: [
      CommonModule,
      RouterModule.forRoot(AppRoutes, config)
   ],
   exports: [RouterModule],
   declarations: []
})
export class AppRoutingModule { }
