<div class="page-main-container">
	<!-- Service grid -->
	<div class="service section-gap" style="padding-bottom: 0px !important;">
		<div class="container">
			<div class="section-title-wrapper">
				<div class="row">
					<div class="col-sm-12 col-md-12">
						<h2>Our Decking</h2>
						<p class="mb-0">
							Renowned for durability, aesthetic and low maintenance, our UPVC and composite decking has the ability to transform any space. Whether for relaxation, al-fresco dining or socialising with friends and family, our decking offers a unique space to enjoy the outdoors, maximising potential and adding value to your outside area.
							<br><br>
						</p>
						<h4>Your safety a priority</h4>
						<p class="mb-0">
							Safety and enjoyment is at the heart of everything we do, our product is slip-resistant and comes with a class 1Y fire safety guarantee. Our decking is the preferred choice, both for businesses and homes, as it will not splinter or weather in the way that traditional decking products might. This means that you can enjoy your outdoor space confidently, knowing we have your safety covered.
							<br><br>
						</p>
						<h4>What are the benefits of UPVC Decking?</h4>
					</div>
				</div>
			</div>
			<div class="row" angly-serviceGrid [serviceGridContent]="services"></div>
			<div class="section-title-wrapper">
				<div class="row">
					<div class="col-sm-12 col-md-12">
						<h4>Inspred by Nature</h4>
						<p class="mb-0">
							With various high-quality designs and styles to decide upon. You will be spoilt for choice when selecting the perfect design for your decking. The unique composition of our UPVC and composite products ensures that, whichever decking you choose, it will remain pristine throughout the year.
							<br><br>
							Our product comes in a comprehensive range of natural colours which will complement and enhance the beauty of any space. We are able to provide a choice between a stainless steel or timber subframe to suit your needs, ensuring that the product you choose is right for you.
							<br><br>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Projects grid-->
	<div class="Gallery-grid">
		<div class="container">
			<div class="section-title-wrapper">
				<div class="row">
					<div class="col-sm-12 col-md-10 mx-auto text-center">
						<h2> Recent Installations </h2>
					</div>
				</div>
			</div>
			<div class="row" angly-gallaryGrid gridClass="col-sm-12 col-md-4 mb-4" [gallaryGridContent]="projectGallary"></div>
		</div>
	</div>

	<!-- Contact information section -->
	<div class="contact-section section-gap">
		<div class="container">
			<div class="section-title-wrapper">
				<div class="row">
					<div class="col-sm-12 col-md-10 mx-auto text-center">
						<h2>Contact Information </h2>
					</div>
				</div>
			</div>
			<div class="row" angly-homeContact [contact]="contact"></div>
		</div>
	</div>

</div>