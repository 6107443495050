/*
 * Send message
 * Used in another component.
 */
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: '[angly-sendMessage]',
  templateUrl: './sendMessage.component.html',
  styleUrls: ['./sendMessage.component.scss']
})
export class SendMessageComponent implements OnInit {

  sendMessageForm: UntypedFormGroup;
  errorMessage: string;
  successMessage: string;

  constructor(private formBuilder: UntypedFormBuilder, private http: HttpClient) {

    this.sendMessageForm = this.formBuilder.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      textarea: [null, [Validators.required]]
    });
  }

  ngOnInit() {
  }

  sendMessage(values: any) {
    console.log(values);
    this.errorMessage = '';
    this.successMessage = '';

    if (this.sendMessageForm.valid) {

      this.http.post('https://api.dimensionsundecks.co.uk/front/postmessage', values)
        .subscribe(
          response => {
            this.successMessage = 'Your message has been sent';
            this.sendMessageForm.reset();
          },
          error => {
            this.errorMessage = 'Failed to submit message';
          }
        );

    } else {
      this.errorMessage = 'Please complete all fields';
    }
  }

}
