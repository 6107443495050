import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';

@Component({
  selector: 'angly-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  /* Variables */
  contact: any;

  lat: number = 54.977179;
  lng: number = -1.4610176;

  constructor(private pageTitleService: PageTitleService, private service: ChkService) {

    /* Page title */
    this.pageTitleService.setTitle(" Get a Quote ");

    /* Page subTitle */
    this.pageTitleService.setSubTitle(" Contact us using the options below and we will get back to you as soon as possbile ");

    this.service.getContactContent().
      subscribe(response => { this.contact = response },
        err => console.log(err),
        () => this.contact
      );
  }

  ngOnInit() {
  }

}
