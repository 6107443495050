<div *ngIf="router?.url != '/sign-up' && router?.url != '/login' && router?.url != '/thank-you' && router?.url != '/not-found' && router?.url != '/maintenance'">
	<!-- Footer section 2 -->

	<!-- <div angly-footerLogoList [logoList]="footerLogo" class="client-logo"></div> -->
	<div class="footer-wrapper-2 py-5 bg-light">
		<div class="container container-full-width">
			<div class="row">
				<div class="col-sm-12 col-md-8 col-lg-8">
					<!-- <div class="bottom-footer mb-2">
						<ul class="list-inline mb-0 font-weight-bold" *ngIf="footer2Menu">
							<li class="list-inline-item" *ngFor="let menu of footer2Menu">
								<a [routerLink]="['/', menu.state]" routerLinkActive="active-link" *ngIf="menu.type === 'link'">
									<span>{{ menu?.name }}</span>
								</a>
							</li>
						</ul>
					</div> -->
					<span class="text-muted">&copy; 2023, All rights reserved </span>
				</div>
				<div class="col-sm-12 col-md-4 col-lg-4">
					<div class="social-without-bg text-right">
						<ul class="list-inline mb-0">
							<li class="list-inline-item"><a href="https://www.facebook.com/dimensionsundecks"><i class="fa fa-facebook"></i></a></li>
							<li class="list-inline-item"><a href="https://www.instagram.com/dimensionsundecks/"><i class="fa fa-instagram"></i></a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Back To Top -->
	<a href="#" id="back-top" class="to-top scrolled" (click)="topScroll($event)"> <span class="to-top-icon"></span> </a>
</div>