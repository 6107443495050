<div class="page-main-container">
	<!-- Service grid -->
	<div class="service">
		<div class="container">
			<div class="section-title-wrapper">
				<div class="row">
					<div class="col-sm-12 col-md-12 text-center">
						<h2>Why Us</h2>
						<div class="text-left">
							<p class="mb-0">At Dimension Sundecks, we are proud to be one of the market leaders for the provision of decking and fencing in the North-East. Specialising in UPVC and composite products, designed for minimal maintenance and stylish aesthetics, Dimension Sundecks are renowned for providing the top-tier selection in decking and fencing.
								<br><br>
								Dimension Sundecks is a family-run business, with over two decades of experience in providing the highest level of customer care. Made in Britain, our product is the go-to for premium decking and fencing solutions. Our team covers installations, both commercial and private, across the North.
								<br><br>
								With expert craftsmanship and outstanding customer service, we guarantee installations of the highest standard. Say farewell to traditional, high-maintenance options and welcome the beauty and practicality of our superior UPVC and composite products.
								<br><br>
							</p>
							<h4>Pricing</h4>
							<p class="mb-0">
								As one of the North-East's leading providers for decking and fencing both commercially and privately, we pride ourselves on offering competitive, affordable prices which guarantee satisfaction and value for money.
								<br><br>
								Customer service and happiness is the cornerstone of our business and we strive to ensure personalised service to suit your budget. Contact us today for a free quote and begin your journey to transform and maximise the potential of your outdoor space.
								<br><br>
							</p>
							<h4>
								Customer service with you at the centre
							</h4>
							<p class="mb-0">
								At Dimension Sundecks, we are dedicated to providing only the finest. Our skilled advisors and designers are on hand to guide you through choosing the ideal product, considering your personal style and budget.
								<br><br>
								Our products are all made right here in Britain. We partner with state-of-the-art factory facilities leveraging years of manufacturing experience to ensure top-quality sourcing.
								<br><br>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row" angly-serviceGrid [serviceGridContent]="services"></div>
		</div>
	</div>

</div>