import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';

@Component({
	selector: 'angly-fencing',
	templateUrl: './fencing.component.html',
	styleUrls: ['./fencing.component.scss']
})
export class FencingComponent implements OnInit {

	/* Variables */
	services: any;
	about: any;
	team: any;
	contact: any;

	constructor(private pageTitleService: PageTitleService, private service: ChkService) {

		/* Page title */
		this.pageTitleService.setTitle(" ");

		/* Page subTitle */
		this.pageTitleService.setSubTitle("");

		this.service.getAbout().
			subscribe(response => { this.about = response },
				err => console.log(err),
				() => this.about
			);
	}


	ngOnInit() {
	}

	/*
	* Social links
	*/
	socialDetails: any = [
		{ url: 'https://www.facebook.com/dimensionsundecks', icon: 'fa-facebook' },
		{ url: 'https://www.instagram.com/dimensionsundecks/', icon: 'fa-instagram text-info' },
	]

	/*
	 * Classes of social ul, li
	 */
	socialsClasses: any = { ulClass: "", liClass: "", linkClass: "" }

}
